import { inject, Injectable } from '@angular/core';
import { ProjectImage, ProjectImagesZipSocket } from 'ngx-q360-lib';

import * as signalR from '@microsoft/signalr';
import { environment } from '@environments/environment';
import { Configuration } from '@environments/configuration';
import { AuthService } from '@app/auth/auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectImageUploadHubService {
  private imageMessages$ = new BehaviorSubject<ProjectImage | null>(null);
  private zipMessages$ = new BehaviorSubject<ProjectImagesZipSocket | null>(null);
  private connection!: signalR.HubConnection;
  private authService = inject(AuthService);
  private projectId: string | null = null;
  private connectInProgress = false;
  private disconnectInProgress = false;
  private lastConnectCall!: ReturnType<typeof setTimeout>;

  async connect(projectId: string): Promise<void> {
    // Debounce connect calls to prevent rapid switching
    if (this.lastConnectCall) {
      clearTimeout(this.lastConnectCall);
    }

    this.lastConnectCall = setTimeout(async () => {
      // Ensure previous connection is fully stopped
      if (this.connectInProgress || this.disconnectInProgress || this.projectId === projectId) {
        return; // If a connection or disconnection is in progress, skip this call
      }

      if (this.projectId !== projectId) {
        await this.disconnect();
      }

      this.connectInProgress = true;
      this.projectId = projectId;

      if (this.connection && this.connection.state !== signalR.HubConnectionState.Disconnected) {
        await this.disconnect(); // Ensure any existing connection is disconnected
      }

      // Build the connection
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(environment.apiUrl + '/projectimage-hub/hub/projectImage', {
          accessTokenFactory: () => this.authService.getAccessToken(),
        })
        .withAutomaticReconnect()
        .configureLogging(Configuration.isProduction ? signalR.LogLevel.None : signalR.LogLevel.Error)
        .build();

      this.connection.on('ProcessImage', (message) => {
        this.imageMessages$.next(message);
      });

      this.connection.on('ZipImages', (message) => {
        this.zipMessages$.next(message);
      });

      this.connection.onreconnected(() => {
        if (this.projectId) {
          // Rejoin project on reconnection
          this.joinProject('JoinProject', this.projectId);
        }
        this.joinProject('JoinForDownloadZip');
      });

      // Start the connection
      try {
        await this.connection.start();
        await this.joinProject('JoinProject', projectId); // Join project after connection is started
        await this.joinProject('JoinForDownloadZip'); // Join project after connection is started
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Connection error:', err);
      } finally {
        this.connectInProgress = false; // Reset connection flag
      }
    }, 500); // Debounce by 500ms to avoid rapid switching
  }

  private async joinProject(methodName: string, projectId?: string): Promise<void> {
    if (this.connection.state === signalR.HubConnectionState.Connected) {
      try {
        if (projectId) {
          await this.connection.invoke(methodName, projectId);
        } else {
          await this.connection.invoke(methodName);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to join project:', err);
      }
    } else {
      setTimeout(() => this.joinProject(methodName, projectId), 1000); // Retry after 1 second
    }
  }

  async disconnect(): Promise<void> {
    if (this.connection && this.connection.state !== signalR.HubConnectionState.Disconnected) {
      this.disconnectInProgress = true;
      try {
        await this.connection.stop();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Disconnection error:', err);
      } finally {
        this.disconnectInProgress = false; // Reset disconnection flag
      }
    }
  }

  getImageMessages$() {
    return this.imageMessages$.asObservable();
  }

  getZipMessages$() {
    return this.zipMessages$.asObservable();
  }
}
