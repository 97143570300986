import {
  CreateCommentPayload,
  ProjectGetImageSettingsPayload,
  ProjectImage,
  ProjectImageAuditItem,
  ProjectImageCreateUploadSessionPayload,
  ProjectImageDeleteObjectPayload,
  ProjectImageFollowerPayload,
  ProjectImagesFileList,
  ProjectImagesQuery,
  ProjectImageUpdateImagePayload,
  ProjectImageUpdateObjectPayload,
  ProjectImageUpdateTagPayload,
  ProjectSaveImageSettingsPayload,
  ProjectSearchImagesQuery,
  QueryFilterModelNew,
  UpdateCommentPayload,
  UpdateProjectImageStatusPayload,
} from 'ngx-q360-lib';

export class ProjectImageSearch {
  static readonly type = '[ProjectImageAnalytics] ProjectImageSearch';
  constructor(public projectId: string) {}
}

export class SetProjectImageSearchQuery {
  static readonly type = '[ProjectImageAnalytics] SetProjectImageSearchQuery';
  constructor(
    public projectId: string,
    public query: ProjectSearchImagesQuery,
    public dispatch: boolean = true,
  ) {}
}

export class GetProjectImageUploadSession {
  static readonly type = '[ProjectImageAnalytics] GetProjectImageUploadSession';
  constructor(public projectId: string) {}
}

export class SetProjectImageUploadSessionQuery {
  static readonly type = '[ProjectImageAnalytics] SetProjectImageUploadSessionQuery';
  constructor(
    public projectId: string,
    public query: QueryFilterModelNew,
  ) {}
}

export class SetProjectImageUploadSession {
  static readonly type = '[ProjectImageAnalytics] SetProjectImageUploadSession';
  constructor(
    public projectId: string,
    public payload: ProjectImageCreateUploadSessionPayload,
    public fileList: ProjectImagesFileList[],
  ) {}
}

export class UpdateProjectImage {
  static readonly type = '[ProjectImageAnalytics] UpdateProjectImage';
  constructor(
    public projectId: string,
    public imageId: string,
    public payload: ProjectImageUpdateImagePayload,
    public showAlert: boolean = false,
  ) {}
}

export class UpdateProjectImageStatus {
  static readonly type = '[ProjectImageAnalytics] UpdateProjectImageStatus';
  constructor(
    public projectId: string,
    public imageId: string,
    public payload: UpdateProjectImageStatusPayload,
  ) {}
}

export class BulkUpdateProjectImage {
  static readonly type = '[ProjectImageAnalytics] BulkUpdateProjectImage';
  constructor(
    public projectId: string,
    public payload: ProjectImageUpdateImagePayload[],
  ) {}
}

export class UpdateProjectImageInSession {
  static readonly type = '[ProjectImageAnalytics] UpdateProjectImageInSession';
  constructor(public payload: ProjectImage) {}
}

export class GetProjectImages {
  static readonly type = '[ProjectImageAnalytics] GetImages';
  constructor(public projectId: string) {}
}

export class GetMoreProjectImages {
  static readonly type = '[ProjectImageAnalytics] GetMoreProjectImages';
  constructor(public projectId: string) {}
}

export class GetProjectImageDetails {
  static readonly type = '[ProjectImageAnalytics] GetImageDetails';
  constructor(
    public projectId: string,
    public imageId: string,
  ) {}
}

export class SetProjectImagesQuery {
  static readonly type = '[ProjectImageAnalytics] SetProjectImagesQuery';
  constructor(
    public projectId: string,
    public query: ProjectImagesQuery,
    public dispatch: boolean = true,
  ) {}
}

export class SaveSettings {
  static readonly type = '[ProjectImageAnalytics] SaveSettings';

  constructor(public payload: ProjectSaveImageSettingsPayload) {}
}

export class GetSettings {
  static readonly type = '[ProjectImageAnalytics] GetSettings';

  constructor(public payload: ProjectGetImageSettingsPayload) {}
}

export class UpdateProjectImageObject {
  static readonly type = '[ProjectImageAnalytics] UpdateProjectImageObject';

  constructor(
    public projectId: string,
    public imageId: string,
    public payload: ProjectImageUpdateObjectPayload,
  ) {}
}

export class DeleteProjectImageObject {
  static readonly type = '[ProjectImageAnalytics] DeleteProjectImageObject';
  constructor(
    public projectId: string,
    public imageId: string,
    public payload: ProjectImageDeleteObjectPayload,
  ) {}
}

export class GetProjectImageTags {
  static readonly type = '[ProjectImageAnalytics] GetProjectImageTags';

  constructor(
    public projectId: string,
    public imageId: string,
    public payload: QueryFilterModelNew,
  ) {}
}

export class UpdateProjectImageTag {
  static readonly type = '[ProjectImageAnalytics] UpdateProjectImageTag';

  constructor(
    public projectId: string,
    public imageId: string,
    public name: string,
    public payload: ProjectImageUpdateTagPayload,
  ) {}
}

export class DeleteProjectImageTag {
  static readonly type = '[ProjectImageAnalytics] DeleteProjectImageTag';
  constructor(
    public projectId: string,
    public imageId: string,
    public name: string,
  ) {}
}

export class CreateProjectImageTag {
  static readonly type = '[ProjectImageAnalytics] CreateProjectImageTag';

  constructor(
    public projectId: string,
    public imageId: string,
    public name: string,
  ) {}
}

export class GetProjectImage {
  static readonly type = '[ProjectImageAnalytics] GetProjectImage';

  constructor(
    public projectId: string,
    public imageId: string,
  ) {}
}

export class DeleteProjectImage {
  static readonly type = '[ProjectImageAnalytics] DeleteProjectImage';
  constructor(
    public projectId: string,
    public imageId: string,
  ) {}
}

export class GetProjectImageAudit {
  static readonly type = '[ProjectImageAnalytics] GetProjectImageAudit';
  constructor(
    public projectId: string,
    public imageId: string,
  ) {}
}

export class RevertProjectImageAudit {
  static readonly type = '[ProjectImageAnalytics] RevertProjectImageAudit';
  constructor(
    public projectId: string,
    public audit: ProjectImageAuditItem,
  ) {}
}

export class UpdateSelectedImageIds {
  static readonly type = '[ProjectImageAnalytics] UpdateSelectedImageIds';
  constructor(public ids: string[]) {}
}

export class DeleteProjectImages {
  static readonly type = '[ProjectImageAnalytics] DeleteProjectImages';
  constructor(
    public projectId: string,
    public imageIds: string[],
  ) {}
}

export class GetImagesByLink {
  static readonly type = '[ProjectImageAnalytics] GetImagesByLink';
  constructor(
    public projectId: string,
    public imageLinkId: string,
  ) {}
}

export class CreateImagesLink {
  static readonly type = '[ProjectImageAnalytics] CreateImagesLink';

  constructor(
    public projectId: string,
    public ids: string[],
  ) {}
}

export class ZipProjectImages {
  static readonly type = '[ProjectImageAnalytics] ZipProjectImages';

  constructor(
    public projectId: string,
    public ids: string[],
    public splitCount: number = 1,
  ) {}
}

export class GetZipList {
  static readonly type = '[ProjectImageAnalytics] GetZipList';

  constructor(public projectId: string) {}
}

export class GetZipFile {
  static readonly type = '[ProjectImageAnalytics] GetZipFile';

  constructor(
    public projectId: string,
    public zipId: string,
  ) {}
}

export class SetProjectImageZipsQuery {
  static readonly type = '[ProjectImageAnalytics] SetProjectImageZipsQuery';
  constructor(
    public projectId: string,
    public query: QueryFilterModelNew,
  ) {}
}

export class AddFollower {
  static readonly type = '[ProjectImageAnalytics] AddFollower';

  constructor(
    public projectId: string,
    public imageId: string,
    public payload: ProjectImageFollowerPayload,
  ) {}
}

export class RemoveFollower {
  static readonly type = '[ProjectImageAnalytics] RemoveFollower';

  constructor(
    public projectId: string,
    public imageId: string,
    public followerId: string,
  ) {}
}

export class GetComments {
  static readonly type = '[ProjectImageAnalytics] GetComments';

  constructor(
    public projectId: string,
    public imageId: string,
  ) {}
}

export class AddComment {
  static readonly type = '[ProjectImageAnalytics] AddComment';

  constructor(
    public projectId: string,
    public imageId: string,
    public payload: CreateCommentPayload,
  ) {}
}

export class UpdateComment {
  static readonly type = '[ProjectImageAnalytics] UpdateComment';

  constructor(
    public projectId: string,
    public imageId: string,
    public commentId: string,
    public payload: UpdateCommentPayload,
  ) {}
}

export class RemoveComment {
  static readonly type = '[ProjectImageAnalytics] RemoveComment';

  constructor(
    public projectId: string,
    public imageId: string,
    public commentId: string,
  ) {}
}
